<template>
  <div class="footer-loc white--text">
    <h4 class="white--text header-1 mb-2">{{ location.title }}</h4>
    <p v-if="location.status === 'coming_soon'" class="r-title">{{
      location.description
    }}</p>
    <div v-else class="address r-title mb-3">
      <div v-if="getContactDetail('street_1')">{{
        getContactDetail('street_1')
      }}</div>
      <div v-if="getContactDetail('street_2')">{{
        getContactDetail('street_2')
      }}</div>
      <span>{{
        `${getContactDetail('city')}, ${getContactDetail('state')}`
      }}</span>
      <a
        v-if="getContactDetail('phone_number')"
        class="location--phone-link"
        :href="`tel:${formatPhone(getContactDetail('phone_number'))}`"
        >{{ `\n${getContactDetail('phone_number')}` }}</a
      >
    </div>
    <BaseButton
      v-if="location.status !== 'coming_soon'"
      v-scroll-to="location.slug === $route.params.locSlug ? '#menu' : ''"
      flat
      color="white"
      small
      right
      :to="`/locations/${location.slug}`"
    >
      View Menu >
    </BaseButton>
  </div>
</template>

<script>
import getContactDetail from '@utils/get-contact-detail'
import formatPhone from '@utils/format-phone'

export default {
  name: 'FooterLocation',
  props: {
    location: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  data() {
    return {}
  },
  methods: {
    formatPhone,
    getContactDetail(key) {
      return getContactDetail(this.location, key)
    }, // getContactDetail
  },
}
</script>

<style lang="scss" scoped>
@import '@design';

.footer-loc {
  .r-title {
    line-height: 1.4;
  }
  .location--phone-link {
    color: white;
    text-decoration: none;
  }
}
</style>
