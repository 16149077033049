<template>
  <div
    v-shortkey="['shift', 'esc']"
    class="footer--outer"
    @shortkey="loginOrToggleUI"
  >
    <BaseThemeComponent v-if="$route.path !== '/'" name="SectionOrderOnline" />

    <BasePageSection
      :class="['footer', { 'pa-0': ['xs', 'sm'].includes($mq) }]"
      bg-color="#6cbd7d"
      full-bleed-bg
    >
      <VLayout>
        <WithLocationSummaries v-slot="{ locations }">
          <VContainer class="locations" fluid grid-list-md>
            <VLayout justify-left align-space-around row wrap fill-height>
              <VFlex
                v-for="(location, i) of locations"
                :key="`location-${i}`"
                grow
                md3
                sm6
                xs12
              >
                <FooterLocation :location="location" />
              </VFlex>
              <VSpacer />
              <VFlex
                :class="[
                  'right-col text-md-right text-sm-left',
                  { 'align-self-end': !['lg', 'xl'].includes($mq) },
                ]"
                md3
                sm6
                xs12
              >
                <BaseButton
                  outline
                  color="white"
                  class="ma-0 footer-contact-btn"
                  @click="showEmailModal = true"
                >
                  {{
                    ['lg', 'xl'].includes($mq)
                      ? 'Send us a Message'
                      : 'Message Us'
                  }}
                </BaseButton>
                <div class="social-links text-xs-left text-md-right">
                  <VFlex
                    v-for="channel of getSocialChannels"
                    :key="channel.name"
                    class="nav-route social-icon text-xs-center mx-0 mb-0 mt-3"
                  >
                    <a :href="channel.url" target="_blank" class="py-3 px-2">
                      <img
                        class="icon-img"
                        :src="
                          require(`@assets/images/icon-${channel.name}-white.svg`)
                        "
                        :alt="channel.name"
                      />
                    </a>
                  </VFlex>
                </div>
              </VFlex>
            </VLayout>
          </VContainer>
        </WithLocationSummaries>
      </VLayout>
      <VContainer
        :class="[
          'white--text',
          {
            'pa-0': ['xs', 'sm'].includes($mq),
          },
        ]"
      >
        <VLayout
          row
          wrap
          :class="[
            {
              'pa-0': ['xs', 'sm'].includes($mq),
            },
          ]"
        >
          <VFlex
            sm6
            order-sm1
            text-sm-left
            xs12
            order-xs2
            text-xs-center
            align-self-center
            :pt-2="['xs', 'sm'].includes($mq)"
          >
            <span
              >&copy; {{ new Date().getFullYear() }} {{ getSiteTitle }}</span
            >
          </VFlex>
          <VSpacer />
          <VFlex sm6 order-sm2 text-sm-right text-xs-center xs12 order-xs1>
            <BaseButton color="white" flat small to="/menus">Menus</BaseButton>
            <BaseButton color="white" flat small to="/about">About</BaseButton>
            <BaseButton color="white" flat small to="/contact"
              >Contact</BaseButton
            >
          </VFlex>
        </VLayout>
      </VContainer>
      <BaseRestoByline />
    </BasePageSection>
    <BaseModalsAndSnackbars />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import WithLocationSummaries from '@dataProviders/WithLocationSummaries'
import FooterLocation from '@components/FooterLocation'

export default {
  name: 'Footer',
  components: {
    WithLocationSummaries,
    FooterLocation,
  },
  data() {
    return {
      showEmailModal: false,
    }
  },
  computed: {
    ...mapGetters('site', ['getSiteTitle', 'getSocialChannels']),
  },
  methods: {
    ...mapActions('auth', ['loginOrToggleUI']),
  }, // methods
}
</script>

<style lang="scss">
.address {
  white-space: pre;
}
.footer-contact-btn {
  max-width: 100%;
}
</style>
