var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"shortkey",rawName:"v-shortkey",value:(['shift', 'esc']),expression:"['shift', 'esc']"}],staticClass:"footer--outer",on:{"shortkey":_vm.loginOrToggleUI}},[(_vm.$route.path !== '/')?_c('BaseThemeComponent',{attrs:{"name":"SectionOrderOnline"}}):_vm._e(),_c('BasePageSection',{class:['footer', { 'pa-0': ['xs', 'sm'].includes(_vm.$mq) }],attrs:{"bg-color":"#6cbd7d","full-bleed-bg":""}},[_c('VLayout',[_c('WithLocationSummaries',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var locations = ref.locations;
return [_c('VContainer',{staticClass:"locations",attrs:{"fluid":"","grid-list-md":""}},[_c('VLayout',{attrs:{"justify-left":"","align-space-around":"","row":"","wrap":"","fill-height":""}},[_vm._l((locations),function(location,i){return _c('VFlex',{key:("location-" + i),attrs:{"grow":"","md3":"","sm6":"","xs12":""}},[_c('FooterLocation',{attrs:{"location":location}})],1)}),_c('VSpacer'),_c('VFlex',{class:[
                'right-col text-md-right text-sm-left',
                { 'align-self-end': !['lg', 'xl'].includes(_vm.$mq) } ],attrs:{"md3":"","sm6":"","xs12":""}},[_c('BaseButton',{staticClass:"ma-0 footer-contact-btn",attrs:{"outline":"","color":"white"},on:{"click":function($event){_vm.showEmailModal = true}}},[_vm._v(" "+_vm._s(['lg', 'xl'].includes(_vm.$mq) ? 'Send us a Message' : 'Message Us')+" ")]),_c('div',{staticClass:"social-links text-xs-left text-md-right"},_vm._l((_vm.getSocialChannels),function(channel){return _c('VFlex',{key:channel.name,staticClass:"nav-route social-icon text-xs-center mx-0 mb-0 mt-3"},[_c('a',{staticClass:"py-3 px-2",attrs:{"href":channel.url,"target":"_blank"}},[_c('img',{staticClass:"icon-img",attrs:{"src":require(("@assets/images/icon-" + (channel.name) + "-white.svg")),"alt":channel.name}})])])}),1)],1)],2)],1)]}}])})],1),_c('VContainer',{class:[
        'white--text',
        {
          'pa-0': ['xs', 'sm'].includes(_vm.$mq),
        } ]},[_c('VLayout',{class:[
          {
            'pa-0': ['xs', 'sm'].includes(_vm.$mq),
          } ],attrs:{"row":"","wrap":""}},[_c('VFlex',{attrs:{"sm6":"","order-sm1":"","text-sm-left":"","xs12":"","order-xs2":"","text-xs-center":"","align-self-center":"","pt-2":['xs', 'sm'].includes(_vm.$mq)}},[_c('span',[_vm._v("© "+_vm._s(new Date().getFullYear())+" "+_vm._s(_vm.getSiteTitle))])]),_c('VSpacer'),_c('VFlex',{attrs:{"sm6":"","order-sm2":"","text-sm-right":"","text-xs-center":"","xs12":"","order-xs1":""}},[_c('BaseButton',{attrs:{"color":"white","flat":"","small":"","to":"/menus"}},[_vm._v("Menus")]),_c('BaseButton',{attrs:{"color":"white","flat":"","small":"","to":"/about"}},[_vm._v("About")]),_c('BaseButton',{attrs:{"color":"white","flat":"","small":"","to":"/contact"}},[_vm._v("Contact")])],1)],1)],1),_c('BaseRestoByline')],1),_c('BaseModalsAndSnackbars')],1)}
var staticRenderFns = []

export { render, staticRenderFns }